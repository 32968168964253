<template>
  <div>
    <v-navigation-drawer
      class="hidden-md-and-up header-one-page-nav-drawer"
      v-model="drawer"
      fixed
      width="320"
    >
      <v-list-item class="pa-5">
        <div class="logo">
          <img
            src="../../assets/images/logo/logo-symbol-dark.png"
            alt="brand-image"
          />
        </div>
        <v-spacer></v-spacer>
        <v-btn class="close-icon" icon @click="drawer = !drawer">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-list-item>
      <scrollactive
        active-class="v-btn--active"
        bezier-easing-value=".5,0,.35,1"
        :offset="71"
      >
        <v-list>
          <v-list-item
            :ripple="false"
            v-for="item in items"
            :key="item.title"
            :to="item.to"
            link
            class="scrollactive-item"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <template v-if="isAuthenticated">
            <v-list-group
              v-for="item in userSidebarDropDownItems"
              :key="item.title"
              v-model="item.active"
              :ripple="false"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                link
                v-for="child in item.items"
                :to="child.to"
                :key="child.title"
                :ripple="false"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="child.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>

          <!-- End sidebar home dropdown item list -->
          <v-list-item v-if="isAuthenticated">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title @click="onLogout()"
              >Se déconnecter</v-list-item-title
            >
          </v-list-item>
          <v-list-item v-if="!isAuthenticated">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title @click="onLogout()"
              >Se connecter</v-list-item-title
            >
          </v-list-item>
          <!-- End mobile menu sidebar item list -->
        </v-list>
      </scrollactive>
    </v-navigation-drawer>
    <!-- End sidebar mobile menu -->

    <v-app-bar
      dark
      color="transparent"
      fixed
      elevate-on-scroll
      class="header-one-page-nav"
    >
      <router-link to="/" class="logo">
        <slot name="logo"></slot>
        <slot name="logo-dark"></slot>
      </router-link>
      <!-- End brand logo -->
      <v-spacer></v-spacer>
      <v-btn
        icon
        :ripple="false"
        class="ma-0 pa-0 hidden-md-and-up menu_icon"
        @click="drawer = !drawer"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <!-- End mobile menu icon -->
      <v-toolbar-items class="hidden-xs-only hidden-sm-only height-auto">
        <scrollactive
          active-class="v-btn--active"
          bezier-easing-value=".5,0,.35,1"
          :offset="71"
        >
          <v-btn
            v-for="item in items"
            :key="item.title"
            :to="item.to"
            link
            :ripple="false"
            text
            class="scrollactive-item"
            >{{ item.title }}
          </v-btn>
        </scrollactive>
        <v-menu
          open-on-hover
          bottom
          min-width="240"
          offset-y
          transition="scroll-y-reverse-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :ripple="false"
              text
              class="compte btn-default btn-border btn-opacity btn-smallheight theme--dark"
              v-bind="attrs"
              v-on="on"
              v-if="!isAuthenticated"
              @click="$router.push({ name: 'Login' }).catch(err => {})"
            >
              Mon compte
            </v-btn>
            <v-btn
              :ripple="false"
              text
              class="compte btn-default btn-border btn-opacity btn-smallheight theme--dark"
              v-bind="attrs"
              v-on="on"
              v-if="isAuthenticated"
              @click="$router.push({ name: 'Dashboard' }).catch(err => {})"
            >
              Mon compte
            </v-btn>
          </template>

          <v-list v-if="isAuthenticated">
            <v-list-item
              link
              v-for="(item, index) in userDropDownItems"
              :key="index"
              :to="item.to"
            >
              <v-list-item-icon class="mr-2">
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title @click="onLogout()">Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
      <!-- End header menu item -->
    </v-app-bar>
    <!-- End top header navbar -->
  </div>
</template>
<style>
.v-app-bar .compte {
  height: 35px !important;
}

.v-app-bar--hide-shadow .compte {
  color: white !important;
}

.v-app-bar--hide-shadow .compte:hover {
  background-color: white !important;
  border-color: white !important;
}

.v-app-bar--is-scrolled .compte {
  color: black !important;
  border-color: black !important;
}

.v-app-bar--is-scrolled .compte span {
  color: black !important;
}

.v-app-bar--is-scrolled .compte:hover {
  background-color: white !important;
  border-color: var(--color-primary) !important;
}

.v-toolbar__items > .v-btn {
  height: 80% !important;
  /* max-height: none; */
}
</style>
<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";

export default {
  data: () => ({
    drawer: false,
    items: [
      { title: "Accueil", to: "/#home" },
      { title: "Service", to: "/#service" },
      { title: "Avantage", to: "/#avantage" },
      { title: "Tarif", to: "/#tarif" },
      { title: "FAQ", to: "/#FAQ" }
    ],
    userSidebarDropDownItems: [
      {
        items: [
          {
            title: "Mes amis",
            to: "/user/dashboard",
            icon: "mdi-badge-account-horizontal-outline"
          },
          {
            title: "Informations personnelles",
            to: "/user/account",
            icon: "mdi-account-group"
          },
          {
            title: "Forfait",
            to: "/user/subscription",
            icon: "mdi-currency-eur"
          },
          {
            title: "Mon assistant",
            to: "/user/activate",
            icon: "mdi-settings-outline"
          }
        ],
        title: "Mon compte"
      }
    ],
    userDropDownItems: [
      {
        title: "Mes amis",
        to: "/user/dashboard",
        icon: "mdi-badge-account-horizontal-outline\n"
      },
      {
        title: "Informations personnelles",
        to: "/user/account",
        icon: "mdi-account-group"
      },
      { title: "Forfait", to: "/user/subscription", icon: "mdi-currency-eur" },

      {
        title: "Mon assistant",
        to: "/user/activate",
        icon: "mdi-cellphone-cog"
      }
    ]
  }),
  methods: {
    async onLogout() {
      this.$store.dispatch(LOGOUT);
    }
  },
  computed: {
    ...mapGetters(["isAuthenticated"])
  }
};
</script>
