<template>
  <footer class="footer-area footer-style-01 bg_color--6">
    <div class="footer-wrapper ptb--70">
      <v-container class="container">
        <v-row>
          <v-col lg="4" md="4" sm="4" cols="12">
            <div class="ft-text">
              <div class="logo">
                <a href="/"><img :src="logo" alt="brand image"/></a>
              </div>
              <p>
                Copyright © {{ new Date().getFullYear() }}
                <a href="https://www.shirkalab.io">shirkaLAB</a> ce service est
                réalisé par shirkaLAB All rights reserved.
              </p>
            </div>
          </v-col>
          <v-col lg="4" md="4" sm="4" cols="12" class="mt_md--40 mt_sm--40">
            <div class="footer-link">
              <h4></h4>
              <ul class="ft-link">
                <li>
                  <router-link to="/user/dashboard"> Mon Compte</router-link>
                </li>
                <li>
                  <router-link to="/google"
                    >Mise en route sur google assistant</router-link
                  >
                </li>
                <li>
                  <router-link to="/alexa">Mise en route sur Alexa</router-link>
                </li>
                <li>
                  <router-link to="/CGU">Mentions légales et CGU</router-link>
                </li>
                <li>
                  <router-link to="/cookies"
                    >Données personnelles et cookies</router-link
                  >
                </li>
                <li>
                  <router-link to="/about">Qui sommes nous ?</router-link>
                </li>
              </ul>
            </div>
          </v-col>
          <v-col lg="2" md="2" sm="2" class="mt_mobile--40">
            <div class="footer-link">
              <h4>Liens Utiles</h4>
              <ul class="ft-link">
                <li><router-link to="/">Accueil</router-link></li>
                <li><router-link to="/#service">le service</router-link></li>
                <li><router-link to="/#avantage">Avantages</router-link></li>
                <li><router-link to="/#tarif">Tarif</router-link></li>
                <li><router-link to="/#FAQ">FAQ</router-link></li>
              </ul>
            </div>
          </v-col>
          <v-col lg="2" md="2" sm="2" class="mt_mobile--40">
            <a href="/vote">
              <img
                width="180"
                :src="
                  require('../../assets/images/silvereco/Innovations-New-Tech.png')
                "
              />
            </a>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      logo: require("../../assets/images/logo/logo-light.png"),
      socialList: [
        {
          icon: "fa-facebook-f",
          url: "https://www.facebook.com/"
        },
        {
          icon: "fa-linkedin-in",
          url: "https://www.linkedin.com/"
        },
        {
          icon: "fa-twitter",
          url: "https://twitter.com/"
        },
        {
          icon: "fa-instagram",
          url: "https://www.instagram.com/"
        }
      ]
    };
  }
};
</script>
