<template>
  <div>
    <!-- Start Header Area -->
    <HeaderOnePageThree v-if="showMenu">
      <img slot="logo" :src="logoLight" class="logo-light" />
      <img slot="logo-dark" :src="logoDark" class="logo-dark" />
    </HeaderOnePageThree>
    <!-- End Header Area -->

    <router-view />

    <Footer v-if="showMenu" />
  </div>
</template>

<script>
import HeaderOnePageThree from "../components/header/HeaderOnePageThree";
import Footer from "../components/footer/Footer";
import { mapGetters } from "vuex";

export default {
  components: {
    HeaderOnePageThree,
    Footer
  },
  data() {
    return {
      logo: require("../assets/images/logo/logo-all-dark.png"),
      logoLight: require("../assets/images/logo/logo-light.png"),
      logoDark: require("../assets/images/logo/logo-dark.png")
    };
  },
  methods: {},

  computed: {
    ...mapGetters(["showMenu"])
  }
};
</script>
